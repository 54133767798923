import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import { updateDoc, getDoc, doc } from 'firebase/firestore'
import { db } from '../firebase.config'
import useAdminAuth from '../hooks/useAdminAuth'

import ContentContainer from '../layout/ContentContainer'

function EditPost() {
  const [course, setCourse] = useState({
    title: '',
    content: '',
  })

  const isAdmin = useAdminAuth()
  const navigate = useNavigate()
  const params = useParams()

  useEffect(() => {
    const fetchPost = async () => {
      const docRef = doc(db, 'courses', params.courseId)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
        setCourse(docSnap.data())
      }
    }
    fetchPost()
  }, [])

  const onChangeTitleHandler = e => {
    setCourse(prevState => ({
      ...prevState,
      title: e.target.value,
    }))
  }

  const onChangeContentHandler = e => {
    setCourse(prevState => ({
      ...prevState,
      content: e.target.value,
    }))
  }

  const onSubmit = async e => {
    e.preventDefault()
    const docRef = doc(db, 'courses', params.courseId)
    await updateDoc(docRef, course)
    toast.success('Storka aktualizována...', {
      style: {
        border: '4px solid #663366',
        padding: '16px',
        color: '#663366',
        fontSize: '1.25rem',
        top: '55px',
      },
      iconTheme: {
        primary: '#663366',
        secondary: '#FFFAEE',
      },
    })

    navigate(`/courses/${params.courseId}`)
  }

  if (!isAdmin) return

  return (
    course && (
      <ContentContainer>
        <form className='editpost' onSubmit={onSubmit}>
          <h2 className='editpost__heading'>Upravit Storku</h2>
          <button className='editpost__button'>upravit</button>
          <input
            className='editpost__input'
            type='text'
            placeholder='nadpis - všechno malým'
            id='edit-title'
            value={course.title}
            onChange={onChangeTitleHandler}
            required
          />
          <textarea
            className='editpost__editor'
            value={course.content}
            onChange={onChangeContentHandler}
            required
            style={{ width: '100%', padding: '2rem', fontSize: '1.5rem' }}
          />
        </form>
      </ContentContainer>
    )
  )
}

export default EditPost
