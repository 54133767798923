import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import toast from 'react-hot-toast'
import useAdminAuth from '../hooks/useAdminAuth'

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage'
import { getDoc, addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { db } from '../firebase.config'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import ContentContainer from '../layout/ContentContainer'

function AddCourse() {
  const isAdmin = useAdminAuth()
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    title: '',
    image: {},
    content: '',
  })

  const onMutateTitle = e => {
    setFormData(prevState => ({
      ...prevState,
      title: e.target.value,
    }))
  }

  const onMutateImage = e => {
    setFormData(prevState => ({
      ...prevState,
      image: e.target.files,
    }))
  }

  const onMutateContent = e => {
    setFormData(prevState => ({
      ...prevState,
      content: e.target.value,
    }))
  }

  const onSubmit = async e => {
    e.preventDefault()

    const storeImage = async image => {
      return new Promise((resolve, reject) => {
        const storage = getStorage()
        const fileName = `${image.name}-${uuidv4()}`

        const storageRef = ref(storage, 'images/' + fileName)

        const uploadTask = uploadBytesResumable(storageRef, image)

        uploadTask.on(
          'state_changed',
          snapshot => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            console.log('Upload is ' + progress + '% done')
            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused')
                break
              case 'running':
                console.log('Upload is running')
                break
              default:
                break
            }
          },
          error => {
            reject(error)
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
              resolve(downloadURL)
            })
          }
        )
      })
    }

    const imgUrl = await storeImage(formData.image[0]).catch(() => {
      console.log('Chyba.')
      return
    })

    const formDataCopy = {
      ...formData,
      imgUrl,
      timeStamp: serverTimestamp(),
    }

    delete formDataCopy.image

    setFormData({
      title: '',
      image: {},
      content: '',
    })

    const docRef = await addDoc(collection(db, 'courses'), formDataCopy)

    toast.success('Kurs přidán...', {
      style: {
        border: '4px solid #663366',
        padding: '16px',
        color: '#663366',
        fontSize: '1.25rem',
        top: '55px',
      },
      iconTheme: {
        primary: '#663366',
        secondary: '#FFFAEE',
      },
    })

    navigate(`/courses/${docRef.id}`)
  }

  if (!isAdmin) return
  return (
    isAdmin && (
      <ContentContainer>
        <form className='addpost' onSubmit={onSubmit}>
          <h2 className='addpost__heading'>Přidat Kurs</h2>
          <button className='addpost__button'>přidat</button>
          <input
            className='addpost__input'
            type='text'
            placeholder='nadpis - všechno malým'
            onChange={onMutateTitle}
            id='title'
            value={formData.title}
            required
          />
          <input
            className='addpost__addImage'
            type='file'
            max='1'
            placeholder='nadpis'
            accept='.jpg,.png,.jpeg,.JPG, .JPEG, .PNG, .webp'
            onChange={onMutateImage}
            id='image'
            required
          />
          <textarea
            className='addpost__editor'
            value={formData.content}
            onChange={onMutateContent}
            required
            style={{
              width: '100%',
              padding: '2rem',
              fontSize: '1.5rem',
            }}
          />
        </form>
      </ContentContainer>
    )
  )
}

export default AddCourse
