import {BsEmojiSmile} from "react-icons/bs"
import ContentContainer from "../layout/ContentContainer"

function Services() {
  return (
    <ContentContainer>
      <div className="services__main-image"></div>
      <div className="services">
        <div className="services__heading">
          <h2>Služby</h2>
        </div>
        <ul className="services__items">
          <li className="services__item">
            <h3 className="services__item-heading">individuální konzultace</h3>
            <p className="services__item-description">
              Každá situace má nějaké řešení, někdy ho však stěží vidíme,
              protože jsme uvnitř problému a potřebujeme pohled někoho zvenčí.
              Pomohu vám najít příčinu toho, proč se cítíte tak, jak se cítit
              nechcete.
            </p>
            <span className="services__item-price">Cena 800 Kč/hodina</span>
          </li>
          <li className="services__item">
            <h3 className="services__item-heading">párová konzultace</h3>
            <p className="services__item-description">
              Cílem je naučit pár řešit spory správnou upřímnou komunikací, s
              úctou, respektem a jasnými pravidly.
              <span className="services__item-bold">
                Při této konzultaci je nezbytné, aby oba zúčastnění chtěli na
                zlepšení vztahu pracovat.{" "}
              </span>
            </p>
            <span className="services__item-price">Cena 1 200 Kč/hodina</span>
          </li>
          <li className="services__item">
            <h3 className="services__item-heading">socionická typologie</h3>
            <p className="services__item-description">
              Socionika se zabývá typologií lidí a umí (mimo jiné) najít vaše
              silné stránky. Socionika je typologie, která je postavená na
              základech C.G. Junga a jelikož se typ hledá při osobní konzultaci,
              kde se jde více do hloubky, než u jiných typologií, je velice
              přesná a člověk se o sobě dozví opravdu hodně věcí.
            </p>
            <span className="services__item-price">
              Cena 3 300 Kč/dvě hodiny
            </span>
          </li>
          <li className="services__item">
            <h3 className="services__item-heading">rozvojová konzultace</h3>
            <p className="services__item-description">
              Navazuje na socionickou typologii a cílem je se ještě více v sobě
              vyznat, přijmout se, pochopit a rozšířit si vědomí o možnostech
              vašeho socionického typu.
            </p>
            <span className="services__item-price">Cena 1 200 Kč/hodina</span>
          </li>
          <li className="services__item">
            <h3 className="services__item-heading">
              zooterapie - caviaterapie (terapie s morčaty) felinoterapie
              (terapie s kočkou)
            </h3>
            <p className="services__item-description">
              Hlazení zvířat má schopnost vytvářet u lidí hormon endorfin, který
              se běžně vyplavuje třeba při sportu a díky kterému pociťujeme
              radost, štěstí a uvolnění. U seniorů ale i mladých lidí s
              mentálním nebo fyzickým omezením se zooterapie velmi osvědčuje.
              Samotný dotyk srsti zvířete působí velmi pozitivně na naše
              klienty. Ti jsou pak klidnější a uvolňuje se jim svalové napětí v
              těle. Některá zvířata, zejména kočky, mají dokonce nadpozemskou
              schopnost přeměnit špatnou energii na dobrou.
              <br />
              Děti s poruchou pozornosti, soustředění a hyperaktivitou, se během
              mazlení s morčátkem, či kočičkou zklidní a učí se koncentrace a
              vnímání sebe a svých pozitivních pocitů.
            </p>
            <span className="services__item-price">Cena 800 Kč/hodina</span>
          </li>
          <li className="services__item">
            <h3 className="services__item-heading">vědomé přednášky</h3>
            <p className="services__item-description">
              Máš zajímavé podnikání, které souvisí se seberozvojem a zdravím?
              Můžeme se sejít, popovídat si a uvidíme, jestli by z toho byla
              zajímavá přednáška. <BsEmojiSmile />
              <br /> Kontaktujte mě na{" "}
              <a href="tel:+420727968287">727 968 287</a> nebo{" "}
              <a href="mailto:zuzanajasnikova@gmail.com">
                zuzanajasnikova@gmail.com
              </a>
              .
            </p>
            <span className="services__item-price"></span>
          </li>
        </ul>
      </div>
    </ContentContainer>
  )
}

export default Services
